import styled from "styled-components";

const Button = styled.button`
  font-size: 24px;
  padding: 10px;
  color: white;
  border-radius: 4px;
  border: 0;
  font-weight: bold;
  background-color: #438cee;
  cursor: pointer;
  transition: all 300ms ease;
  outline: none;
  &:hover {
    opacity: 0.8;
  }
`;

export default Button;
