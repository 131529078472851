import styled from "styled-components";

export const PostContainer = styled.section`
  max-width: 50em;
  margin: 0 auto;
  padding: 16px;
`;

export const ProjectHeader = styled.section`
  margin: 32px 0px;
  .project-image {
    padding: 32px 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    max-width: 600px;

    @media only screen and (max-width: 1024px) {
      height: auto;
      max-width: 100%;
    }
  }
`;

export const MakerHeader = styled.section`
  h1,
  h2 {
    margin: 16px 0;
  }
  img {
    padding: 16px 0px 0px 16px;
    max-height: 250px;
    border-radius: 4px;
  }

  display: flex;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;

export const Description = styled.p`
  font-size: 32px;
  color: #3b3f45;
`;

export const Pill = styled.p`
  background-color: ${props => props.c};
  margin: 16px 0;
  margin-right: 4px;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;
  display: inline-block;
  text-transform: capitalize;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const SmallPill = styled.p`
  background-color: ${props => props.c};
  margin: 0px 4px;
  padding: 6px;
  border-radius: 8px;
  font-weight: bold;
  display: inline-block;
  text-transform: capitalize;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const PostData = styled.div``;

export const Button = styled.button`
  font-size: 24px;
  padding: 10px;
  margin: 4px;
  color: white;
  border-radius: 4px;
  border: 0;
  font-weight: bold;
  background: #438cee;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.8;
  }
`;

export const Date = styled.h4`
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: inline-block;
  float: left;
`;

export const Author = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  img {
    display: block;
    margin: 0 auto;
    margin-top: 16px;
    padding: 0;
    height: 40px;
    border-radius: 50%;
    transition: all 300ms ease;
  }
  h3 {
    margin-top: 8px;
    background: #f4fbff;
    color: #438cee;
    padding: 4px;
    display: inline-block;
  }
`;

export const Project = styled.article`
  margin: 32px 0;
  line-height: 1.5;
  font-size: 18px;
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 16px 0;
  }

  p {
    padding: 8px 0;
  }
  a {
    color: #2f80ed;
    border-bottom: 1px solid #d3d3d3;
    transition: all 300ms ease;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    width: auto;
    height: auto;
    max-height: 600px;
    padding: 16px 0;
  }
  .language-text {
    background: #f4fbff !important;
    color: #006d99 !important;
    padding: 4px;
  }
`;

export const ArticleWrapper = styled.div`
  position: relative;

  summary:focus {
    outline: none;
  }

  details {
    margin-left: 32px;
  }

  hr {
    color: #f5f5f5;
  }

  img {
    max-width: 75%;
    max-height: 400px;
  }

  ul, li {
    list-style-type: disc;
    margin-left: 12px;
  }

  h1 {
    font-size: 1.875rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: bold;
  }
`;

export const Share = styled.div`
  position: absolute;
  left: -100px;
  top: 18px;
  text-align: center;

  div,
  svg {
    cursor: pointer;
    transition: all 300ms ease;
    margin: 8px 0px;
    &:hover {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const MakerShare = styled.div`
  position: absolute;
  left: -100px;
  top: 75px;
  text-align: center;

  div,
  svg {
    cursor: pointer;
    transition: all 300ms ease;
    margin: 8px 0px;
    &:hover {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const PostAd = styled.div`
  position: absolute;
  right: 20px;
  top: 60px;

  @media only screen and (max-width: 1350px) {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Feedback = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  max-width: 150px;
  -moz-box-shadow: 0 0 15px #e4e4e4;
  -webkit-box-shadow: 0 0 15px #e4e4e4;
  box-shadow: 0 0 15px #e4e4e4;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;

  @media only screen and (max-width: 1350px) {
    display: none;
  }
`;
