import React, { Component } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import Helmet from "react-helmet";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import AuthUserContext from "../components/Session/AuthUserContext";
import withAuthorization from "../components/Session/withAuthorization";

import {
  fetchSubmittedTutorialById,
  deleteSubmittedTutorial,
  updateSubmittedTutorial,
  pushSubmittedTutorialToWeb,
  addEnlights,
  addOneToTutorialCount
} from "api/db";
import { auth } from "api/firebase";

import { postEnlights } from "../constants/constants";

import { Row, Col } from "react-grid-system";

import { Project, ArticleWrapper } from "components/Post/styles";
import HeaderLivePreview from "components/Post/HeaderLivePreview";

import hljs from "highlight.js";
import Markdown from "react-remarkable-with-plugins";
import Spinner from "react-spinkit";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const highlight = (str, lang) => {
  if (lang && hljs.getLanguage(lang)) {
    try {
      return hljs.highlight(lang, str).value;
    } catch (err) {
      console.error(err);
    }
  }

  try {
    return hljs.highlightAuto(str).value;
  } catch (err) {
    console.error(err);
  }

  return "";
};

const Container = styled.div`
  font-size: 20px;
  margin: auto;
  max-width: 95%;
  padding: 32px;
`;

const PreviewContainer = styled.div`
  max-width: 50em;
  margin: 0 auto;
  height: 600px;
  overflow: auto;
`;

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

class DraftsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: "",
      author_uid: "",
      author_username: "",
      email: "",
      title: "",
      description: "",
      language: "",
      difficulty: "",
      category: "",
      img: "",
      demoURL: "",
      sourceURL: "",
      content: ".",
      date: "",
      id: "",
      published: false,
      loading: true,
      key: "",
      projectUpdated: false,
      pushedWeb: false
    };
  }

  componentDidMount() {
    let tutorial = this.props.location.pathname.slice(8);
    this.setState({ id: tutorial });
    this.getTutorialData(tutorial);
  }

  getTutorialData = tutorial => {
    fetchSubmittedTutorialById(tutorial).once(
      "value",
      function(snapshot) {
        let data = snapshot.val();
        data = Object.entries(data);
        let key = data[0][0];
        data = data[0][1];
        this.setState({
          author: data.author,
          author_uid: data.author_uid,
          author_username: data.author_username,
          email: data.email,
          title: data.title,
          description: data.description,
          language: data.language,
          difficulty: data.difficulty,
          category: data.category,
          img: data.img,
          demoURL: data.demoURL,
          sourceURL: data.sourceURL,
          content: data.content,
          date: data.date,
          projectId: data.id,
          published: data.published,
          key: key,
          loading: false
        });
      }.bind(this)
    );
  };

  onUpdateProject = event => {
    event.preventDefault();
    updateSubmittedTutorial(
      this.state.author,
      this.state.author_uid,
      this.state.author_username,
      this.state.category,
      this.state.content,
      this.state.date,
      this.state.demoURL,
      this.state.description,
      this.state.difficulty,
      this.state.email,
      this.state.id,
      this.state.img,
      this.state.language,
      this.state.published,
      this.state.sourceURL,
      this.state.title,
      this.state.key
    );
    this.setState({ projectUpdated: true });
  };

  onPushToWeb = event => {
    event.preventDefault();
    addOneToTutorialCount().then((numTutorial) => {
      var projectId = numTutorial.snapshot.node_.value_;
      pushSubmittedTutorialToWeb(
        this.state.author,
        this.state.author_uid,
        this.state.author_username,
        this.state.category,
        this.state.content,
        this.state.date,
        this.state.demoURL,
        this.state.description,
        this.state.difficulty,
        this.state.id,
        this.state.img,
        this.state.language,
        projectId,
        this.state.published,
        this.state.sourceURL,
        this.state.title
      );
    })
    
    this.setState({ pushedWeb: true });
    addEnlights(this.state.author_uid, postEnlights);
  };

  onDeleteSubmission = event => {
    event.preventDefault();
    deleteSubmittedTutorial(this.state.key);
    navigate("/admin/dashboard");
  };

  render() {
    if (
      auth.currentUser.uid == "qODlzGtsA0QMv5hxNqC0qy6lIRy1" ||
      auth.currentUser.uid == "7bqTTkoDvua4M3lJDpLD2Ga9Lmp2" ||
      auth.currentUser.uid == "yN5k2h91h3WFNudUWfBT3suVVep2"
    ) {
      return (
        <div>
          <Helmet title="Edit Project Details | Enlight" />
          <AuthUserContext.Consumer>
            {authUser => (
              <div>
                <Container>
                  <Label>
                    {" "}
                    <a href="/admin/dashboard"> &larr; Back</a>{" "}
                  </Label>
                  <br />

                  {this.state.loading ? (
                    <SpinnerContainer>
                      <Spinner name="ball-scale-multiple" color="#438cee" />
                    </SpinnerContainer>
                  ) : (
                    <div>
                      <Row>
                        <Col>
                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event => {
                                this.setState(
                                  updateByPropertyName(
                                    "title",
                                    event.target.value
                                  )
                                );
                                let data = event.target.value;
                                let id = data
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();
                                this.setState(updateByPropertyName("id", id));
                              }}
                              type="text"
                              placeholder="Project Title"
                              value={this.state.title}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "description",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Description"
                              value={this.state.description}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "language",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Language"
                              value={this.state.language}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "category",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Category: Web, ML, etc"
                              value={this.state.category}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "difficulty",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Difficulty: Beginner, Intermediate, Hard"
                              value={this.state.difficulty}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "img",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Image Link"
                              value={this.state.img}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "demoURL",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="Project Demo Link"
                              value={this.state.demoURL}
                            />
                          </div>

                          <div className="input-submit">
                            <input
                              className="large input-center"
                              onChange={event =>
                                this.setState(
                                  updateByPropertyName(
                                    "sourceURL",
                                    event.target.value
                                  )
                                )
                              }
                              type="text"
                              placeholder="GitHub Repo Link"
                              value={this.state.sourceURL}
                            />
                          </div>
                        </Col>
                        <Col>
                          <HeaderLivePreview
                            title={this.state.title}
                            description={this.state.description}
                            writer={this.state.author}
                            writerId={this.state.author_username}
                            category={this.state.category}
                            language={this.state.language}
                            difficulty={this.state.difficulty}
                            date={this.state.date}
                            id={this.state.id}
                            img={this.state.img}
                            source={this.state.sourceURL}
                            demourl={this.state.demoURL}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <textarea
                            className="submission"
                            onChange={event => {
                              this.setState(
                                updateByPropertyName(
                                  "content",
                                  event.target.value
                                )
                              );
                            }}
                            type="text"
                          >
                            {this.state.content}
                          </textarea>
                        </Col>
                        <Col sm={6}>
                          <PreviewContainer>
                            <ArticleWrapper>
                              <Project>
                                <Markdown options={{ highlight, html: true }}>
                                  {this.state.content}
                                </Markdown>{" "}
                              </Project>
                            </ArticleWrapper>
                          </PreviewContainer>
                        </Col>
                      </Row>

                      <br />
                      {this.state.projectUpdated ? (
                        <Button>Done &#10004;</Button>
                      ) : (
                        <Button onClick={this.onUpdateProject}>
                          Update Project in Submissions
                        </Button>
                      )}

                      <br />
                      <br />

                      {this.state.pushedWeb ? (
                        <Button>Done &#10004;</Button>
                      ) : (
                        <Button onClick={this.onPushToWeb}>
                          Push Project to Web
                        </Button>
                      )}
                      <br />
                      <br />
                      <Button onClick={this.onDeleteSubmission}>
                        Delete from Submissions
                      </Button>
                    </div>
                  )}
                </Container>
              </div>
            )}
          </AuthUserContext.Consumer>
        </div>
      );
    } else {
      return "404";
    }
  }
}
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(DraftsPage);
