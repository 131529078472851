import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import { fetchUserData } from "api/db";

import AuthUserContext from "components/Session/AuthUserContext";
import { SIGN_IN } from "constants/routes";

import {
  ProjectHeader,
  Description,
  Pill,
  PostData,
  Button,
  Date,
  Author,
  PostAd
} from "./styles";

const Header = ({
  title,
  description,
  writer,
  writerId,
  category,
  language,
  difficulty,
  date,
  id,
  img,
  source,
  demourl
}) => {
  const [authorImage, setAuthorImage] = useState();

  let username = writerId;

  useEffect(() => {
    if (username) {
      fetchUserData(username).once("value", function(snapshot) {
        snapshot.forEach(childSnap => {
          let childData = childSnap.val();
          setAuthorImage(childData.photoURL);
        });
      });
    }
  });

  return (
    <ProjectHeader>
      <h1 className="text-4xl tracking-tight leading-none font-extrabold text-gray-800">
        {title}
      </h1>
      <h2 className="text-2xl leading-snug text-gray-700 mt-2">
        {description}
      </h2>

      <div className="mt-4 mb-4">
        <a
          href={`/category/${category}`}
          className="tracking-tight rounded-full py-2 px-4 bg-blue-200 text-gray-800 mr-1 font-bold text-xs md:text-base"
        >
          {category}
        </a>
        <a
          href={`/language/${language}`}
          className="tracking-tight rounded-full py-2 px-4 bg-teal-200 text-gray-800 mr-1 font-bold text-xs md:text-base"
        >
          {language}
        </a>
        <a
          href={`/difficulty/${difficulty}`}
          className="tracking-tight rounded-full py-2 px-4 bg-pink-200 text-gray-800 font-bold text-xs md:text-base"
        >
          {difficulty}
        </a>
      </div>

      <PostData className="text-gray-800">
        <Date>{date}</Date>
      </PostData>
      <br />
      <img className="project-image" src={img} />
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <div className="tc">
              <a href={source} target="_blank">
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                  Source
                </button>
              </a>
              <a href={demourl} target="_blank">
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Demo
                </button>
              </a>
            </div>
          ) : (
            <div className="tc">
              <Link to={SIGN_IN}>
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Login to Download Project & Start Coding
                </button>
              </Link>
            </div>
          )
        }
      </AuthUserContext.Consumer>

      <div className="tc mt-4">
        <Link to={`/user/${username}`}>
          <img
            className="inline object-cover w-12 h-12 mr-2 rounded-full"
            src={authorImage}
            alt="Profile image"
          />
          <p className="mt-2 text-gray-700"> {writer} </p>
        </Link>
      </div>
    </ProjectHeader>
  );
};

export default Header;
